





































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable }
})
export default class AnnuityComputation extends Vue {
  calcType: string = 'calc_periods';
  mortgage: number = 0;
  rate: number = 0;
  annuity: number = 0;
  periods: number = 0;
  beginning: boolean = true;

  loading: boolean = false;

  result: any = null;

  get headers() {
    return [
      { align: 'right', text: this.$i18n.t('common.month'), value: 'period', sortable: false },
      { align: 'right', text: 'Rate', value: 'annuity', sortable: false },
      { align: 'right', text: 'Tilgung', value: 'repayment', sortable: false },
      { align: 'right', text: 'Zins', value: 'interest', sortable: false },
      { align: 'right', text: 'Abgezahlt', value: 'redemption', sortable: false },
      { align: 'right', text: 'Restbetrag', value: 'rest', sortable: false }
    ];
  }

  @Watch('calcType')
  onTypeChange() {
    this.result = null;
  }

  async save(event: MouseEvent) {
    event.preventDefault();

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    try {
      const response = await this.$http.get('api/annuity-computation/calculate', {
        responseType: 'json',
        params: {
          calc_type: this.calcType,
          mortgage: this.mortgage,
          rate: this.rate,
          annuity: this.annuity,
          periods: this.periods,
          beginning: !this.beginning
        }
      });

      this.loading = false;
      this.result = response.data;
    } catch (error) {
      console.warn(error);
      this.loading = false;
    }
  }
}
